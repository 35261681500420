/* .rbc-time-slot {
  min-height: 40px;
} */

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-even {
  display: block;
}

/* .rbc-event {
  height: auto !important;
} */
.rbc-time-column .rbc-timeslot-group {
  background-color: #fff;
}
.rbc-row {
  background-color: #fff;
}
.rbc-time-slot {
  padding-top: 8px;
}
.rbc-header {
  padding: 8px 0;
}
.rbc-calendar {
  overflow-y: scroll;
  /* margin-top: 24px; */
}
.rbc-timeslot-group {
  min-height: 130px;
}
.rbc-event-label {
  display: none;
}
.rbc-allday-cell {
  display: none;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: none;
}
@media print {
  .css-r5r38p-MuiContainer-root {
    padding: 0 !important;
  }
  .no-print {
    display: none !important;
  }
  table {
    zoom: 0.5;
  }
  table td {
    font-size: 1.1rem !important;
  }
  table td span {
    color: #000 !important;
    font-size: 1.1rem !important;
  }
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  overflow: inherit;
}
::-ms-reveal {
  display: none;
}
